import React, { useCallback, useState, useRef, useEffect, memo } from "react";
import {
  Table,
  createStyles,
  rem,
  Box,
  Tooltip,
  Flex,
  Loader,
} from "@mantine/core";

import styles from "./styles.module.scss";
import ScrollAreaLayout from "../../../widgets/ScrollAreaLayout";
import { leaveAfterComma } from "../../../utils/leaveAfterComma";

import axiosInstance from "../../../api/axios.instance";
import toast from "react-hot-toast";

import clsx from "clsx";
import EmptyNotice from "../EmptyNotice";
import { IconCoinOff } from "@tabler/icons-react";
import ToastWarning from "../../../toast/components/toastWarning/ToastWarning";
import { modals } from "@mantine/modals";
import SkeletonTable from "../../../pages/ResearcherPage/SkeletonTable";

const UnpaidUnitModal = memo(() => {
  const [scrolled, setScrolled] = useState(false);
  const [unpaidUnitsData, setUnpaidUnitsData] = useState({
    isLoading: true,
    data: {
      main: [],
      total: {},
    },
  });

  const useStyles = createStyles((theme) => ({
    header: {
      position: "sticky",
      top: 0,
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
      transition: "box-shadow 150ms ease",

      "&::after": {
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        borderBottom: `${rem(1)} solid ${
          theme.colorScheme === "dark"
            ? theme.colors.dark[3]
            : theme.colors.gray[2]
        }`,
      },
    },

    scrolled: {
      boxShadow: theme.shadows.sm,
    },
  }));

  const { classes, cx } = useStyles();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axiosInstance.get(
          "/users/unpaidUnits/personal",
          {
            params: {
              sort: "desc",
            },
          }
        );

        switch (data.status) {
          case "warning":
            toast.custom(<ToastWarning text={data.message} />);
            modals.closeAll();
            break;
          case "success":
            setUnpaidUnitsData((prev) => ({ ...prev, data: data.apiData }));
            break;
        }
      } catch (err) {
        console.log(err);

        toast.error(
          err?.response?.data?.message
            ? err.response.data.message
            : "Server-side error"
        );

        modals.closeAll();
      } finally {
        setUnpaidUnitsData((prev) => ({ ...prev, isLoading: false }));
      }
    })();
  }, []);

  const onOpenVideoInfoPage = useCallback((videoId) => {
    window.open(`/library/${videoId}`, "_blank");
  }, []);

  return (
    <div className={styles.modal}>
      <Box className={styles.tableWrapper}>
        {unpaidUnitsData.isLoading ? (
          <SkeletonTable columnCount={7} rowCount={5} />
        ) : !unpaidUnitsData.data.main.length ? (
          <Flex
            justify="center"
            align="center"
            className={styles.emptyNoticeBlock}
          >
            <EmptyNotice
              icon={<IconCoinOff size="25px" color="black" />}
              text="It's empty here yet"
              color="black"
            />
          </Flex>
        ) : (
          <ScrollAreaLayout setScrolled={setScrolled} height={450}>
            <Table withColumnBorders withBorder className={styles.table}>
              <thead
                className={cx(classes.header, {
                  [classes.scrolled]: scrolled,
                })}
              >
                <tr>
                  <th>Date</th>
                  <th>Video ID</th>
                  <th>Company</th>
                  <th>Count sales</th>
                  <th>The amount of the sale</th>
                  <th>Your earnings</th>
                  <th>Due for</th>
                </tr>
              </thead>

              <tbody>
                {unpaidUnitsData.data.main.map((obj, index) => (
                  <tr key={index}>
                    <td>{obj.date}</td>
                    <td
                      className={styles.cursorPointer}
                      onClick={() => onOpenVideoInfoPage(obj.videoId)}
                    >
                      {obj.videoId}
                    </td>
                    <td>{obj.hasOwnProperty("company") ? obj.company : "-"}</td>
                    <td>
                      {obj.hasOwnProperty("countSales") ? obj.countSales : "-"}
                    </td>

                    <td>
                      {obj.earning.hasOwnProperty("total") ? (
                        <>
                          <>{leaveAfterComma(obj.earning.total, 2)}</>
                          <span className={clsx([styles.gray])}> $</span>
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      {leaveAfterComma(obj.earning.own, 2)}
                      <span className={clsx([styles.gray])}> $</span>
                    </td>
                    <td>{obj.dueFor}</td>
                  </tr>
                ))}
              </tbody>

              <tfoot
                className={cx(classes.header, {
                  [classes.scrolled]: scrolled,
                })}
              >
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th className={clsx([styles.green])}>
                    <>
                      <>
                        {leaveAfterComma(
                          unpaidUnitsData.data.total["earnings.own"],
                          2
                        )}
                      </>
                      <span className={clsx([styles.gray])}> $</span>
                    </>
                  </th>
                  <th></th>
                </tr>
              </tfoot>
            </Table>
          </ScrollAreaLayout>
        )}
      </Box>
    </div>
  );
});

export default UnpaidUnitModal;
