import React, { memo } from "react";
import { Link } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../utils/windowDimensions.js";

import clsx from "clsx";

import { ReactComponent as InstagramIcon } from "../../shared/assets/images/instagram.svg";
import { ReactComponent as YoutubeIcon } from "../../shared/assets/images/youtube.svg";
import { ReactComponent as FacebookIcon } from "../../shared/assets/images/facebook.svg";

import styles from "./Footer.module.scss";
import classNames from "classnames";

const Footer = memo(() => {
  const { width } = useWindowDimensions();

  const { t } = useTranslation();

  return (
    <div className={classNames(styles.footer)}>
      <div className="container">
        <div className={styles.top_line}>
          <div className={styles.left_column}>
            <Link to={"/contactUs"}>
              <div className="font25">{t("contactUs")}</div>
            </Link>
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = "mailto:Licensing@viralbear.media";
                e.preventDefault();
              }}
            >
              <div className="font16">Licensing@viralbear.media</div>
            </Link>
          </div>
          <div className={classNames(styles.right_column, styles.socialmedia)}>
            <a
              className={clsx([styles.socialMediaLink, styles.instagramBg])}
              href="https://www.instagram.com/viralbearrr"
              target="_blank"
            >
              <InstagramIcon />
            </a>
            <a
              className={clsx([styles.socialMediaLink, styles.youtubeBg])}
              href="https://www.youtube.com/@viralbear_yt"
              target="_blank"
            >
              <YoutubeIcon width="16px" />
            </a>
            <a
              className={clsx([styles.socialMediaLink, styles.facebookBg])}
              href="https://www.facebook.com/viralbearrr/"
              target="_blank"
            >
              <FacebookIcon />
            </a>
          </div>
        </div>
        <div className={styles.bottom_line}>
          <div className={styles.left_column}>
            <div className="font13"> © {t("rights")}</div>
          </div>
          <div className={styles.right_column}>
            <Link className={styles.link} to="/privatePolicy">
              {t("privacyPolicy")}
            </Link>

            <Link className={styles.link} to="/termsOfUse">
              {t("termsOfUse")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Footer;
